var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-lg-5", attrs: { fluid: "" } },
    [
      _c("app-header", {
        attrs: {
          title: _vm.title,
          breadcrumbs: _vm.breadcrumbs,
          "disable-button": ""
        }
      }),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c("goals-table", {
            attrs: {
              tableName: _vm.goalTables.OTHER_GOALS,
              goals: _vm.goals,
              loading: _vm.loading
            },
            on: { updateOptions: _vm.updateOptions }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }